import apiClient from "../api/apiClient";

export const getExchangerList = async () => {
    try {
        const response = await apiClient.get("api/account/register");
        let listReceived = [];

        if (response.status === 200) {
            for (const [_, value] of Object.entries(response.data)) {
                listReceived.push(value as string);
            }
            return listReceived
        }
    } catch (error) {
        console.error('Error getting exchanger list:', error);
        alert(error);
        return null
    }
}