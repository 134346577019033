import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import AppContextProvider from "./store/appContext"
import {BrowserRouter} from "react-router-dom";
import UserContextProvider from "./store/userContext";
import { KonstaProvider } from 'konsta/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <AppContextProvider>
              <UserContextProvider>
                <KonstaProvider theme="ios">
                  <App />
                </KonstaProvider>
              </UserContextProvider>
          </AppContextProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
