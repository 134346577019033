import apiClient from '../api/apiClient';
import { User } from '../types/user.type';

export const checkUser = async (initData: string, initDataUnsafe: WebAppInitData, setAuthenticated: React.Dispatch<React.SetStateAction<boolean>>, setUser: React.Dispatch<React.SetStateAction<User | null>>) => {

    try {
        const response = await apiClient.post("api/account/login", {
            webAppData: initData,
            userId: initDataUnsafe?.user?.id
        });

        if (response.status === 200) {
            setUser(response.data)
            setAuthenticated(true);
        }
    } catch (error) {
        console.error('Error checking user:', error);
    }
};