import {useState, useEffect, useContext} from 'react';
import {Routes, Route} from "react-router-dom";

import {useWebApp} from "@vkruglikov/react-telegram-web-app";
import {useHapticFeedback} from "@vkruglikov/react-telegram-web-app";

import './App.css';
import {StartPage} from "./pages";
import {Registration} from "./pages/registration"
import {Settings} from "./pages/settings";
import {checkUser} from "./utilities/checkUser"
import { UserContext } from "./store/userContext";
import { UserContextType } from './types/user.type';
import { TelegramWebApp } from './types/telegram.type';
import { MainLayout } from './components/MainLayout';
import { Marketplace } from './pages/marketplace';
import { Dashboard } from './pages/dashboard';

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const webApp: TelegramWebApp = useWebApp();
    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();
    const {  isAuthenticated, setAuthenticated, user, setUser  } = useContext(UserContext) as UserContextType;

    useEffect(() => {
        const fetchData = (async () => { // Асинхронная IIFE
            if (webApp.initDataUnsafe) {
                await checkUser(webApp.initData, webApp.initDataUnsafe, setAuthenticated, setUser);
                if (isAuthenticated) {
                    notificationOccurred('success');
                    setIsUserLoggedIn(true)
                    setIsLoading(false)
                }
                else {
                    notificationOccurred('error');
                    setIsUserLoggedIn(false)
                    setIsLoading(false)
                }
            }
        })();
    }, [webApp.initDataUnsafe, setAuthenticated, notificationOccurred]);

    useEffect(() => {
        if (user) {
            setIsUserLoggedIn(true)
        } else {
            setIsUserLoggedIn(false)
        }
    }, [user]);

    return (
        <>
            <Routes>
                {isLoading ? (
                    <Route path="/" element={<StartPage version={webApp.version}/>} />
                ) : isUserLoggedIn ? (
                    <Route path="/" element={<MainLayout />}>
                        <Route path="/" element={<Settings />} />
                        <Route path=":itemId" element={<Settings />} />
                        <Route path="/addAccount" element={<Registration tgDataSafe={webApp.initData} tgUserId={webApp.initDataUnsafe?.user?.id} />} />
                        <Route path="/marketplace" element={<Marketplace />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                    </Route>
                ) : (
                    <Route path="/" element={<Registration tgDataSafe={webApp.initData} tgUserId={webApp.initDataUnsafe?.user?.id} />} />
                )}
            </Routes>
        </>
    );
}

export default App;
