import { MouseEventHandler, PropsWithChildren} from "react"

interface ListElementProps extends PropsWithChildren {
    onClick?: MouseEventHandler<HTMLDivElement>;
}

export const ListElement = ({children, onClick}: ListElementProps) => {
    return (
        <div className="flex flex-row bg-transparent" onClick={onClick}>
            {children}
        </div>
    )
}