import { Page } from "konsta/react"

export const StartPage = ({ version }: { version: string }) => {
    return (
        <Page className="flex items-center justify-center" >
            <div>
                <img src={require("../static/loading_icon.gif")} alt="loading animation" height="100" width="151"/>
            </div>
        </Page>
    )
}