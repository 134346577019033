import React, { createContext,  useState } from 'react';
import { User, UserContextType } from '../types/user.type';

export const UserContext = createContext<UserContextType>({
    user: null,
    isAuthenticated: false,
    setUser: () => {},
    setAuthenticated: () => {},
  });

const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<User | null>(null)
    const [isAuthenticated, setAuthenticated] = useState(false);

    return (
        <UserContext.Provider value={{ isAuthenticated, setAuthenticated, user, setUser }}>
            {children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
