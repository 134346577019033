import { Outlet } from "react-router-dom";
import { BottomMenu } from "./BottomMenu";

export const MainLayout: React.FC = () => {
    return (
        <div>
            <BottomMenu />
            <Outlet />
        </div>
    );
};