import { ListButton, Page } from "konsta/react"
import { useContext, useState } from "react";

import { UserContext }  from "../store/userContext";
import { ApiKey } from "../types/user.type";
import { useNavigate } from "react-router-dom";
import { ListContainer } from "../components/ListContainer";
import { ListElement } from "../components/ListElement";
import { TwoRowsTextAreaWithBottomBorder } from "../components/TwoRowsTextAreaWithBottomBorder";

export const Settings = () => {
    const {user} = useContext(UserContext);
    const [selectedExchanger, setSelectedExchanger] = useState<ApiKey | null>(null);
    const navigate = useNavigate();

    const handleClick = (exchanger: ApiKey) => {
        navigate(`/${exchanger.id}`);
        setSelectedExchanger(exchanger);
    };

    return (
        <Page>
            <div className="m-4">
                {selectedExchanger ? (   
                    <div className="flex flex-row gap-4">
                        <h2 className="text-sky-500" onClick={() => {navigate(-1); setSelectedExchanger(null)}}><small>&lt; Settings</small></h2>
                        <h2>Account</h2>
                    </div>
                ) : (   
                    <h2 className="ml-2">Settings</h2>
                )}
                <div>
                {selectedExchanger ? (
                    <ListContainer>
                        <ListElement>
                            <TwoRowsTextAreaWithBottomBorder>
                                <div className="flex flex-col ml-2">
                                    <p className="text-slate-400"><small>Account Name</small></p>
                                    <p>{selectedExchanger.name}</p>
                                </div>
                            </TwoRowsTextAreaWithBottomBorder>
                        </ListElement>
                        <ListElement>
                            {/* place for exchanger image */}
                            <div className="w-16"></div>
                            <TwoRowsTextAreaWithBottomBorder>
                                <div className="flex flex-col">   
                                    <p className="text-slate-400"><small>Exchange</small></p>
                                    <p>{selectedExchanger.market}</p>
                                </div>
                            </TwoRowsTextAreaWithBottomBorder>
                        </ListElement>
                        <ListElement>
                            {/* place for key icon */}
                            <div className="w-16"></div>
                            <TwoRowsTextAreaWithBottomBorder>
                                <div className="flex flex-col"> 
                                    <p className="text-slate-400"><small>API Key</small></p>
                                    <p>{selectedExchanger.key}</p>
                                </div>
                            </TwoRowsTextAreaWithBottomBorder>
                        </ListElement>
                        <ListElement>
                            {/* place for key icon */}
                            <div className="w-16"></div>
                            <TwoRowsTextAreaWithBottomBorder>
                                <div className="flex flex-col">
                                    <p className="text-slate-400"><small>API Secret</small></p>
                                    <p>********</p>
                                </div>
                            </TwoRowsTextAreaWithBottomBorder>
                        </ListElement>
                    </ListContainer>
                ) : (
                    <ListContainer>
                        {user?.apiKeys.map((apiKey, index) => (
                            <ListElement key={index} onClick={() => handleClick(apiKey)}>
                                {/* place for exchanger image */}
                                <div className="w-16"></div>
                                <TwoRowsTextAreaWithBottomBorder>
                                    <div className="flex flex-col">
                                        <p>{apiKey.name}</p>
                                        <p className="text-slate-400"><small>Expires {changeDateFormat(apiKey.expirationDate)}</small></p>
                                    </div>
                                    <div className="text-slate-400 text-xl mr-1">⟩</div>
                                </TwoRowsTextAreaWithBottomBorder>
                            </ListElement>
                        ))}
                        <div className="flex flex-row items-center text-sky-500"  onClick={() => navigate("/addAccount")}>
                            <div className="w-16 flex text-4xl content-center justify-center">+</div>
                            <div className="flex flex-col w-full">Add Account</div>
                        </div>
                    </ListContainer>
                )}
                </div>
            </div>
        </Page>
    )
}

const changeDateFormat = (date: string | null): string => {
    let changedString = "";
    if (date === null) {
        return ""
    }
    const isoDate = new Date(date);
    changedString = isoDate.getDate() + "." + (isoDate.getMonth() + 1) + "." + isoDate.getFullYear();
    return changedString
}