import React, { createContext, useState } from 'react';

export const AppContext = createContext({
    telegramData:  null,
    setTelegramData: (telegramData: any | null) => {},
});

const AppContextProvider = ({children}: {children: React.ReactNode }) => {
    const [telegramData, setTelegramData] = useState<any | null>(null);

    return (
        <AppContext.Provider value={{ telegramData, setTelegramData }}>
            {children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;