import axios from 'axios';
import config from '../config.json';

const apiClient = axios.create({
    baseURL: config.apiUrl,
    timeout: 10000, // установить таймаут в 10000 мс (10 секунд)
    headers: {
      'Content-Type': 'application/json', // установить заголовок Content-Type для всех запросов
      // Другие глобальные заголовки
    },
  });
  
apiClient.interceptors.request.use(config => {
  const token = localStorage.getItem('jwtToken');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
}, error => {
  alert(error);
  return Promise.reject(error);
});

export default apiClient;
