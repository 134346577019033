import { Button, Checkbox, ListInput, ListItem, Page, Toast } from "konsta/react";
import { useCallback, useContext, useEffect, useState } from "react";
import apiClient from "../api/apiClient";
import { UserContext }  from "../store/userContext";
import { getExchangerList } from "../utilities/getExchangerList";
import { useHapticFeedback } from "@vkruglikov/react-telegram-web-app";
import { useLocation, useNavigate } from "react-router-dom";

interface RegistrationProps {
    tgUserId: number | undefined;
    tgDataSafe: string | undefined;
}
type Exchanger = string | null;

export const Registration = (props: RegistrationProps) => {
    const { tgUserId, tgDataSafe } = props;
    const [impactOccurred, notificationOccurred, selectionChanged] = useHapticFeedback();

    const [exchanger, setExchanger] = useState<Exchanger>(null);
    const [exchangerList, setExchangerList] = useState<Exchanger[]>([])
    const [apiKey, setApiKey] = useState("");
    const [apiSecret, setApiSecret] = useState("");
    const [agreement, setAgreement] = useState(false);
    const [errorToast, setErrorToast] = useState(false);

    const {user, setAuthenticated, setUser} = useContext(UserContext);
    const navigate = useNavigate();
    const location = useLocation();

    const onExchangerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setExchanger(e.target.value)
    };
    const onApiKeyChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiKey(e.target.value)
    };
    const onApiSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setApiSecret(e.target.value)
    };
    const onAgreementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAgreement(e.target.checked)
    }

    useEffect(() => {
        const fetchData = (async () => { 
            const listReceived = await getExchangerList() 
            if (listReceived) {
                setExchangerList(listReceived)
            }
            else {
                notificationOccurred('error');
            }
        })()
    }, [])

    const submitForm = useCallback(async () => {
        const data = { apiKey: apiKey, apiSecret: apiSecret, userId: tgUserId, isTestNet: true, market: 0};
        const exchangerIndex = exchangerList.findIndex(element => element === exchanger)
        let headers = {}
        if (user) {
            headers = {
                'Authorization': `Bearer ${user.jwt}`,
                'Content-Type': 'application/json'}
        } 

        try {
            const response = await apiClient.post('api/account/register', {...data, market: exchangerIndex !== -1 ? exchangerIndex : 0}, {headers});
            if (response.data === "Key Invalid") {
                return;
            }
            setUser(response.data);          
            setAuthenticated(true);
            if (location.pathname !== "/") {
                navigate("/")
            } 
        }
        catch (error) {
            alert(error);
            setErrorToast(true)
        };
    }, [apiKey, apiSecret, tgUserId,tgDataSafe, setAuthenticated])
    
    return (
        <Page className="flex flex-col items-center">
            {/*don't forget to delete*/}
            <span>tgUserId: {tgUserId}</span>
            <span>initData: {tgDataSafe}</span>

            <div className="w-2/3 flex flex-col mt-24 mb-10">
            <ListInput
                label="Select Exchange"
                type="select"
                dropdown
                placeholder="Select exchange"
                className="registration-form"
                value={exchanger}
                onChange={onExchangerChange}
            >
                {exchangerList
                    .map((exchanger) => typeof exchanger === "string" ? <option value={exchanger} key={exchanger}>{exchanger}</option> : null)
                    .filter((exchanger) => exchanger !== null)
                }
            </ListInput>
            <ListInput
                label="Api key"
                type="text"
                placeholder="Api key"
                className="registration-form"
                value={apiKey}
                onChange={onApiKeyChange}
            />
            <ListInput
                label="Api secret"
                type="text"
                placeholder="Api secret"
                className="registration-form"
                value={apiSecret}
                onChange={onApiSecretChange}
            />
            <div className="pl-4 mt-2 flex flex-row items-center"><Checkbox
                name="Agree to terms and conditions"
                checked={agreement}
                onChange={onAgreementChange}
                className="mr-2"
            />
                <p>Agree to terms and conditions</p>
            </div>
            </div>
            <div className={`flex flex-col gap-4 {user ? "w-1/2" : "w-1/3"}`}>
                <Button rounded onClick={submitForm} disabled={!agreement}>{user ? "Add exchanger" : "Sign up"}</Button>
                {user ? (
                    <Button rounded outline onClick={() => navigate(-1)}>{'<'} Back</Button>
                    ) : (
                    <Button rounded outline>Skip {'>'}{'>'}</Button>
                    )
                }
                <Toast
                    position="center"
                    opened={errorToast}
                    className="bg-red-400"
                    button={
                        <Button
                            rounded
                            clear
                            small
                            inline
                            onClick={() => setErrorToast(false)}
                        >
                            Close
                        </Button>
                    }
                ><p className="my-2">Incorrect data entered</p></Toast>
            </div>
            </Page>
    )
}
