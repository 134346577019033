import { Button, Navbar } from "konsta/react"
import { useLocation, useNavigate } from "react-router-dom";

export const BottomMenu: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    return (
        <div className="bottom-menu">
            <Navbar className="h-12 px-4 bg-white border-t-2 flex flex-row gap-2">
                <Button onClick={() => {navigate("/marketplace")}} className={`bottom-menu-button ${location.pathname==="/marketplace" ? "!text-blue-500" : "!text-gray-600"}`}>Marketplace</Button>
                <Button onClick={() => {navigate("/dashboard")}} className={`bottom-menu-button  ${location.pathname==="/dashboard" ? "!text-blue-500" : "!text-gray-600"}`}>Dashboard</Button>
                <Button onClick={() => {navigate("/")}} className={`bottom-menu-button  ${location.pathname==="/" ? "!text-blue-500" : "!text-gray-600"}`}>Settings</Button>
            </Navbar>
        </div>
    );
};